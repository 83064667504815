<template>
  <div class="flex flex-col">
    <div
      v-if="cat.data && cat.data.length > 0"
      v-for="subcategory in cat.data"
      class="px-5"
    >
      <Disclosure v-slot="{ open }">
        <DisclosureButton class="w-full">
          <div
            class="
              flex flex-row
              justify-between
              bg-white
              p-2
              rounded-lg
              items-center
              min-w-full
            "
            :class="open ? 'rounded-b-none' : 'mb-2'"
          >
            <div class="flex flex-row items-center">
              <i
                class="w-5 ml-1 text-gray-300 cursor-pointer"
                :class="open ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"
              ></i>
              <p class="ml-3 text-gray-500 font-semibold">
                {{ subcategory.name }}
              </p>
              <i class="fas fa-slash transform scale-75 rotate-90"></i>
              <p class="text-gray-500 font-thin">{{ subcategory.type.name }}</p>
            </div>
            <div class="flex flex-row gap-2 mx-5 items-center text-sm">
              <p
                class="cursor-pointer hover:underline"
                @click="edit(subcategory)"
              >
                Uredi
              </p>
              <p>|</p>
              <p
                class="cursor-pointer hover:underline"
                @click="del(subcategory)"
              >
                Izbriši
              </p>
              <!-- <i @click="test" class="fas fa-pen text-gray-500 z-10"></i>
              <i class="fas fa-trash-alt text-red-500"></i> -->
            </div>
          </div>
        </DisclosureButton>
        <DisclosurePanel
          class="text-gray-500 border-8 border-white rounded-lg p-5"
          :class="open ? 'border-t-0 rounded-t-none bg-repeat-round mb-2' : ''"
        >
          <div class="flex flex-col">
            <div class="flex flex-row gap-2">
              <p class="font-bold">_id:</p>
              <p>{{ subcategory._id }}</p>
            </div>
            <div class="flex flex-row gap-2">
              <p class="font-bold">Ime:</p>
              <p>{{ subcategory.name }}</p>
            </div>
            <div class="flex flex-row gap-2">
              <p class="font-bold">Vrsta:</p>
              <p>{{ subcategory.type.name }}</p>
            </div>
            <div class="flex flex-row gap-2">
              <p class="font-bold">Nadležna kategorija:</p>
              <p>{{ subcategory.parent_category.name }}</p>
            </div>
            <p class="font-bold">Atributi:</p>
            <hr />
            <div class="flex flex-col list-disc">
              <div v-for="atr in subcategory.attributes">
                <div class="flex flex-row gap-3 items-center">
                  <!-- <i class="fas fa-times transform scale-75 text-gray-500"></i> -->
                  •
                  <p>{{ atr }}</p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </DisclosurePanel>
        <hr class="mb-2" />
      </Disclosure>
    </div>
    <div v-else>Nema podkategorija. Dodajte ih.</div>
  </div>

  <EditSubcategoryModal ref="dialog_edit"> </EditSubcategoryModal>
  <DeleteSubcategoryModal ref="dialog_delete"> </DeleteSubcategoryModal>
</template>

<script>
import EditSubcategoryModal from "@/components/Dialogs/EditSubcategoryModal";
import DeleteSubcategoryModal from "@/components/Dialogs/DeleteSubcategoryModal";

import { getSubcategories } from "@/helpers/api";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    EditSubcategoryModal,
    DeleteSubcategoryModal,
  },

  data() {
    return { cat: [] };
  },
  async mounted() {
    this.cat = await getSubcategories();
    this.cat = this.cat.data;
  },
  methods: {
    edit(d) {
      this.$refs.dialog_edit.openModal(Object.create(d));
    },
    del(d) {
      this.$refs.dialog_delete.openModal(Object.create(d));
    },
  },
};
</script>
