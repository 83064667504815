<template>
  <div class="fixed flex items-center justify-center"></div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                xl:w-1/2
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Uredi podkategoriju
              </DialogTitle>
              <div class="mt-2">
                <div class="flex flex-col gap-1">
                  <div class="flex flex-row gap-2">
                    <p class="font-bold">_id:</p>
                    <p>{{ subcategoryData._id }}</p>
                  </div>
                  <div class="flex flex-row gap-2">
                    <p class="font-bold">Ime:</p>
                    <input class="px-1 w-full" v-model="subcategoryData.name" />
                  </div>
                  <div class="flex flex-row gap-2 w-full items-center">
                    <p class="font-bold">Vrsta:</p>
                    <Listbox v-model="odabranaVrsta">
                      <div class="relative w-full">
                        <ListboxButton
                          class="
                            relative
                            w-full
                            h-8
                            pl-5
                            text-left
                            rounded-lg
                            cursor-default
                            border-2
                            sm:text-sm
                          "
                        >
                          <span class="block truncate">{{
                            odabranaVrsta.name
                          }}</span>
                          <span
                            class="
                              absolute
                              inset-y-0
                              right-0
                              flex
                              items-center
                              pr-2
                              pointer-events-none
                            "
                          >
                            <SelectorIcon
                              class="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </ListboxButton>

                        <transition
                          leave-active-class="transition duration-100 ease-in"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ListboxOptions
                            class="
                              z-10
                              absolute
                              w-full
                              py-1
                              mt-1
                              overflow-auto
                              text-base
                              bg-white
                              rounded-md
                              shadow-lg
                              max-h-60
                              ring-1 ring-black ring-opacity-5
                              focus:outline-none
                              sm:text-sm
                            "
                          >
                            <ListboxOption
                              v-slot="{ active, selected }"
                              v-for="v in vrsta"
                              :key="v.name"
                              :value="v"
                              as="template"
                            >
                              <li
                                :class="[
                                  active ? 'bg-gray-100' : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-10 pr-4',
                                ]"
                              >
                                <span
                                  :class="[
                                    selected ? 'font-bold' : 'font-normal',
                                    'block truncate',
                                  ]"
                                  >{{ v.name }}</span
                                >
                                <span
                                  v-if="selected"
                                  class="
                                    absolute
                                    inset-y-0
                                    left-0
                                    flex
                                    items-center
                                    pl-3
                                    text-gray-600
                                  "
                                >
                                  <CheckIcon
                                    class="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </li>
                            </ListboxOption>
                          </ListboxOptions>
                        </transition>
                      </div>
                    </Listbox>
                  </div>

                  <div class="flex flex-row gap-2 w-full items-center">
                    <p class="font-bold w-min">Nadležna kategorija:</p>
                    <Listbox v-model="selectedCategory">
                      <div class="relative w-full">
                        <ListboxButton
                          class="
                            relative
                            w-full
                            h-8
                            pl-5
                            text-left
                            rounded-lg
                            cursor-default
                            border-2
                            sm:text-sm
                          "
                        >
                          <span class="block truncate">
                            {{ selectedCategory.name }}
                          </span>
                          <span
                            class="
                              absolute
                              inset-y-0
                              right-0
                              flex
                              items-center
                              pr-2
                              pointer-events-none
                            "
                          >
                            <SelectorIcon
                              class="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </ListboxButton>

                        <transition
                          leave-active-class="transition duration-100 ease-in"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ListboxOptions
                            class="
                              z-10
                              absolute
                              w-full
                              py-1
                              mt-1
                              overflow-auto
                              text-base
                              bg-white
                              rounded-md
                              shadow-lg
                              max-h-60
                              ring-1 ring-black ring-opacity-5
                              focus:outline-none
                              sm:text-sm
                            "
                          >
                            <ListboxOption
                              v-slot="{ active, selected }"
                              v-for="c in categories"
                              :key="c.name"
                              :value="c"
                              as="template"
                            >
                              <li
                                :class="[
                                  active ? 'bg-gray-100' : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-10 pr-4',
                                ]"
                              >
                                <span
                                  :class="[
                                    selected ? 'font-bold' : 'font-normal',
                                    'block truncate',
                                  ]"
                                  >{{ c.name }}</span
                                >
                                <span
                                  v-if="selected"
                                  class="
                                    absolute
                                    inset-y-0
                                    left-0
                                    flex
                                    items-center
                                    pl-3
                                    text-gray-600
                                  "
                                >
                                  <CheckIcon
                                    class="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </li>
                            </ListboxOption>
                          </ListboxOptions>
                        </transition>
                      </div>
                    </Listbox>
                  </div>
                  <p class="font-bold">Atributi:</p>
                  <hr />
                  <div class="flex flex-col list-disc">
                    <div v-for="atr in atributi">
                      <div class="flex flex-row gap-3 items-center">
                        <i
                          @click="removeAtribute(atributi.indexOf(atr))"
                          class="
                            cursor-pointer
                            fas
                            fa-times
                            transform
                            scale-75
                            text-gray-500
                          "
                        ></i>
                        <input
                          class="px-1 w-full"
                          v-model="atributi[atributi.indexOf(atr)]"
                        />
                      </div>
                      <hr />
                    </div>
                    <div class="flex flex-row gap-3 pr-1 my-2">
                      <input
                        class="input w-full"
                        type="text"
                        v-model="atributInput"
                        @keyup.enter="dodajAtribut"
                      />
                      <button @click="dodajAtribut">
                        <i class="fas fa-plus text-gray-500"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <button type="button" class="btn-secondary" @click="save()">
                  SPREMI
                </button>
                <button type="button" class="btn-primary" @click="closeModal()">
                  ZATVORI
                </button>
                {{ message }}
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { editSubcategory, getCategories } from "@/helpers/api.js";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },

  setup() {
    const vrsta = [
      { name: "Samostojeći", type: 0 },
      { name: "Ugradbeni", type: 1 },
      { name: "Samostojeći i ugradbeni", type: 2 },
    ];
    const odabranaVrsta = ref(vrsta[0]);

    let atributi = ref([]);
    let atributInput = ref();
    let message = ref();
    let isOpen = ref(false);
    let subcategoryData = ref({});

    let categories = ref([]);
    let selectedCategory = ref({});

    const openModal = (d) => {
      subcategoryData.value = d;
      atributi.value = subcategoryData.value.attributes.slice();

      selectedCategory.value = categories.value.find(
        (c) => c.name == subcategoryData.value.parent_category.name
      );
      //   selectedCategory.value = subcategoryData.value.parent_category;
      isOpen.value = true;
    };

    const closeModal = () => (isOpen.value = false);

    const dodajAtribut = () => {
      if (atributInput.value.length == 0) return;
      atributi.value.push(atributInput.value);
      atributInput.value = "";
    };

    const removeAtribute = (index) => {
      atributi.value.splice(index, 1);
    };

    const loadCategories = async () => {
      categories.value = await getCategories();
      categories.value = categories.value.data.data;
    };

    const save = async (id) => {
      const data = {
        _id: subcategoryData.value._id,
        name: subcategoryData.value.name,
        type: odabranaVrsta.value,
        attributes: atributi.value,
        parent_category: selectedCategory.value,
      };
      try {
        let resp = await editSubcategory(data);
        message.value = resp.data.message;
        setTimeout(() => {
          message.value = "";
        }, 5000);
      } catch (error) {
        message.value = error.response.data.message;
        setTimeout(() => {
          message.value = "";
        }, 5000);
      }
    };

    return {
      atributi,
      atributInput,
      dodajAtribut,
      vrsta,
      odabranaVrsta,
      save,
      message,
      isOpen,
      subcategoryData,
      openModal,
      closeModal,
      removeAtribute,
      selectedCategory,
      categories,
      loadCategories,
    };
  },

  async mounted() {
    await this.loadCategories();
  },
};
</script>
