<template>
  <div class="fixed flex items-center justify-center"></div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Potvrdite brisanje podkategorije:
                <strong>{{ podkategorija.name }}</strong>
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Jeste li sigurni da želite izbrisati podkategoriju:
                  <strong>{{ podkategorija.name }}</strong>
                </p>
              </div>

              <div class="mt-4">
                <button type="button" class="btn-secondary" @click="del()">
                  IZBRIŠI
                </button>
                <button type="button" class="btn-primary" @click="closeModal()">
                  ZATVORI
                </button>
                {{ message }}
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { deleteSubcategory } from "@/helpers/api.js";
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },

  setup() {
    let isOpen = ref(false);
    let podkategorija = ref({});
    let message = ref();
    const openModal = (d) => {
      podkategorija.value = d;
      console.log(podkategorija.value);
      isOpen.value = true;
    };
    const closeModal = () => (isOpen.value = false);
    const del = async () => {
      try {
        let resp = await deleteSubcategory(podkategorija.value);
        message.value = resp.data.message;
        setTimeout(() => {
          message.value = "";
        }, 5000);
      } catch (error) {
        message.value = error.response.data.message;
        setTimeout(() => {
          message.value = "";
        }, 5000);
      }
    };
    return {
      isOpen,
      openModal,
      closeModal,
      del,
      podkategorija,
      message,
    };
  },
};
</script>
